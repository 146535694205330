import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/telegram.svg'
import _imports_1 from '@/assets/images/viber.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_footer = _resolveComponent("q-footer")!

  return (_openBlock(), _createBlock(_component_q_footer, {
    id: "footer",
    class: "row justify-center"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("section", { id: "contacts-panel" }, [
        _createElementVNode("article", { id: "social-network-img-container-desktop" }, [
          _createElementVNode("a", { href: "https://t.me/BVRbot" }, [
            _createElementVNode("img", {
              class: "social-network-img",
              src: _imports_0
            })
          ]),
          _createElementVNode("a", { href: "https://bvr.ua/_viber " }, [
            _createElementVNode("img", {
              class: "social-network-img",
              src: _imports_1
            })
          ])
        ]),
        _createElementVNode("article", null, [
          _createElementVNode("div", { class: "contact" }, "bank@bvr.com.ua"),
          _createElementVNode("div", { class: "contact-description" }, "Пиши нам на пошту")
        ]),
        _createElementVNode("article", { id: "social-network-img-container-mobile" }, [
          _createElementVNode("a", { href: "https://t.me/BVRbot" }, [
            _createElementVNode("img", {
              class: "social-network-img",
              src: _imports_0
            })
          ]),
          _createElementVNode("a", { href: "https://bvr.ua/_viber " }, [
            _createElementVNode("img", {
              class: "social-network-img",
              src: _imports_1
            })
          ])
        ]),
        _createElementVNode("article", null, [
          _createElementVNode("div", { class: "contact" }, "044 492 26 00"),
          _createElementVNode("div", { class: "contact-description" }, " За тарифами вашого оператора ")
        ]),
        _createElementVNode("article", null, [
          _createElementVNode("div", { class: "contact" }, "0 800 302 600"),
          _createElementVNode("div", { class: "contact-description" }, "Безкоштовно по Україні")
        ])
      ], -1)
    ])),
    _: 1
  }))
}